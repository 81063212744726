import { effect, inject, signal } from '@angular/core';
import { createInjectable } from 'ngxtension/create-injectable';
import {
  combineLatest,
  EMPTY,
  endWith,
  filter,
  finalize,
  fromEvent,
  map,
  Observable,
  of,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { PropertiesState } from 'src/app/map/features/property/data-access/state';
import { signalSlice2 } from 'src/app/map/features/property/signal-slice';
import { PdfService } from './pdf.service';
import { calculateMapScale, getExtent, getFilenameFromContentDisposition, mapElement, parseAndSignalElement, saveFile } from './util';
import { Router } from '@angular/router';
import { MapService } from 'src/app/map/data-access/map.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { mapArray } from 'ngxtension/map-array';

import { ScenarioState } from 'src/app/map/features/property/features/feature-layers/data-access/scenario/state';
import { FeatureLayerState } from 'src/app/map/features/property/features/feature-layers/data-access/state';
import { ElementLayoutDetails, PDFPageLayout, PdfPrintDefinition } from './models/models';
import { SignalRService } from '../../services/signalR.service';
import { filterNil } from 'ngxtension/filter-nil';
import { GetScenarioRequest } from 'src/app/map/features/property/features/feature-layers/data-access/scenario/models/models';
import { combine } from '@turf/turf';
import { Property } from 'src/app/map/features/property/data-access/models/property.model';
import { LayerDTO } from 'src/app/map/features/property/features/feature-layers/data-access/feature-layers.service';

const INITIAL_STATE: {
  layouts: any[],
  selectedLayout: PDFPageLayout,
  legendDefinitions: any[],
  propertyID: any,
  scenarioID: any,
  extent: [number, number,number,number] | null,
  mapScale: any,
  selectedBaseMap: any,
  elements: any[],
  paperDefinition: {
    size: 'A4' | 'A3',
    orientation: 'Portrait' | 'Landscape',
  },
  processing: boolean
  jobID: any
} = {
  layouts: [],
  selectedLayout: null,
  legendDefinitions: [],
  propertyID: null,
  scenarioID: null,
  extent: [0,0,0,0],
  mapScale: null,
  selectedBaseMap: null,
  elements: [],
  paperDefinition: {
    size: 'A4',
    orientation: 'Portrait',
  },
  processing: false,
  jobID: null,
};

export const PDFState = createInjectable(
  () => {
    const propertiesState = inject(PropertiesState);
    const featureLayerState = inject(FeatureLayerState);
    const scenarioState = inject(ScenarioState);
    const signalR = inject(SignalRService);
    const mapService = inject(MapService);
    const router = inject(Router);
    const pdfAPI = inject(PdfService);

    const olMap = mapService.getMap();

    const moveEnd$ = fromEvent(olMap, 'moveend');

    const renderComplete$ = fromEvent(olMap, 'rendercomplete');

    const selectedScenarioID$ = scenarioState.selectedScenario$.pipe(filter(val => val != null),map((scenario: GetScenarioRequest) => scenario.scenarioID));

    const legendDefinitions$ = combineLatest<[LayerDTO[], Property]>([
      featureLayerState.featureLayers$,
      propertiesState.activeProperty$.pipe(filter((property): property is Property => property != null))
    ]).pipe(
      map(([featureLayers, activeProperty]) =>
        featureLayers.map(featureLayer => ({
          name: featureLayer.name,
          style: featureLayer.style,
          area: featureLayer.area,
          percentage: (featureLayer.area / activeProperty.areaHaCalculated) * 100,
          isLandUse: featureLayer.isLandUse
        })).sort((a, b) => Number(b.isLandUse) - Number(a.isLandUse))
      )
    );


    // ;

    const extent$ = moveEnd$.pipe(
      startWith(true),
      map(() => getExtent({ map: olMap, from: 'EPSG:3857', to: 'EPSG:4326' }))
    );

    const mapScale$ = moveEnd$.pipe(
      startWith(true),
      map(() =>
        calculateMapScale({
          units: olMap.getView().getProjection().getUnits(),
          resolution: olMap.getView().getResolution(),
        })
      )
    );


    const STATE = signalSlice2({
      initialState: INITIAL_STATE,
      sources: [
        propertiesState.activePropertyID$.pipe(
          map((propertyID) => ({ propertyID }))
        ),
        pdfAPI.getLayouts().pipe(
          mapArray((layout) => {
            return {
              ...layout,
              elements: layout.elements.map((element: any) =>
                parseAndSignalElement(element)
              ),
            };
          }),
          map((layouts) => ({
            layouts: layouts,
            selectedLayout: layouts[0],
            paperDefinition: layouts[0].paperDefinition,
          }))
        ),
        extent$.pipe(map((extent: any) => ({ extent }))),
        mapScale$.pipe(map((mapScale) => ({ mapScale }))),
        selectedScenarioID$.pipe(map(scenarioID => ({scenarioID})) ),
        legendDefinitions$.pipe(map(legendDefinitions => ({legendDefinitions})))

      ],
      actionSources: {
        setSelectedLayout: (state, action$: Observable<PDFPageLayout>) =>
          action$.pipe(
            map((selectedLayout) => ({
              selectedLayout,
              paperDefinition: selectedLayout.paperDefinition,
            }))
          ),
          setSelectedBaseMap: (state, action$: Observable<string>) =>
            action$.pipe(
              map((selectedBaseMap) => ({
                selectedBaseMap
              }))
            ),
        generatePDFRequest: (state, action$: Observable<void>) => action$.pipe(
          tap(() => STATE.setProcessing(true)),
          map<void, PdfPrintDefinition>(() => {
          const {selectedLayout,scenarioID, propertyID, extent, selectedBaseMap, paperDefinition, mapScale} = state();
          const elements = selectedLayout.elements.map(element => mapElement(element)) as ElementLayoutDetails[];

          return {elements,propertyID,scenarioID,extent,selectedBaseMap,paperDefinition,mapScale}
        }),
        switchMap(model => pdfAPI.createPrintRequest(model).pipe()),switchMap((jobID) => signalR.joinRoom(jobID))
        ,switchMap(() => signalR.onFileReady().pipe(finalize(() => {
        }))),map((jobID) => ({jobID}))),

        setProcessing: (state, action$: Observable<boolean>) => action$.pipe(map(val => ({processing: val}))),
      },
    });

    effect(() => {
      const activePropertyID = propertiesState.activePropertyID();

      if (activePropertyID == null) {
        router.navigate(['map']);
      }
    });


    STATE.jobID$.pipe(filterNil(),takeUntilDestroyed(),switchMap((jobID:string) => pdfAPI.getFile(jobID).pipe(switchMap(response => {
      return signalR.leaveRoom(jobID).pipe(map(() => response))
    })))).subscribe(response => {
              const contentDisposition = response.headers.get('Content-Disposition');
        const filename =getFilenameFromContentDisposition(contentDisposition);

        saveFile(response.body, filename);

        STATE.setProcessing(false);

    })

    return STATE;
  },
  { providedIn: 'scoped' }
);
