<section #sheet id="pdf-container" class="test sheet padding-5mm">
  <div #map class="map"></div>
  <div>
    @for (item of state.elements(); track $index) {
      @if (item.visible()) {

        @if (item.inputType() === 'EDITOR'){
          <pdf-draggable [position]="item.position()" (positionChanged)="item.position.set($event)">
            <div class="ql-editor editor-renderer" [innerHTML]="item.content() | safeHtml"></div>
          </pdf-draggable>
        }

        @else if (item.inputType() === 'MAP_SCALE') {
          <pdf-draggable
          (positionChanged)="
            item.position.set($event)
          "
          [position]="item.position()"
        >
            <pdf-map-scale [mapScale]="state.mapScale()"></pdf-map-scale>
        </pdf-draggable>
        }

        @else if (item.inputType() === 'IMAGE') {
          <pdf-draggable
          (positionChanged)="
            item.position.set($event)
          "
          [position]="item.position()"
        >
          <img src="assets\laguna_bay_logo.png" alt="" />
        </pdf-draggable>

        }

        @else if (item.inputType() === 'PROPERTY_DETAILS') {
          <pdf-draggable
          (positionChanged)="
            item.position.set($event)
          "
          [position]="item.position()"
        >
        <app-pdf-property-details
        [selectedFields]="item.content()"
        [property]="state.property()"
      >
      </app-pdf-property-details>
        </pdf-draggable>

        }
        @else if (item.inputType() === 'PROPERTY_LAYERS')
        {
          <pdf-draggable
          (positionChanged)="
            item.position.set($event)
          "
          [position]="item.position()"
        >
        <div class="legend-container overflow-x-auto">
          <table class="min-w-full table-auto border-collapse">
            <thead>
              <tr>
                <th class="px-1 py-2 text-left text-gray-700">Legend</th>
                <th class="px-1 py-2 text-center text-gray-700">Name</th>
                <th class="px-1 py-2 text-center text-gray-700">LandUse</th>
                <th class="px-1 py-2 text-center text-gray-700">Area (ha)</th>
                <th class="px-1 py-2 text-center text-gray-700">%</th>
              </tr>
            </thead>
            <tbody>
              @for (layer of state.featureLayerLegendMetaData(); track $index) {
                <tr class="border-t">
                  <td class="px-1 py-2 whitespace-nowrap">
                    <legend-icon [rule]="layer.style.rules[0]"></legend-icon>
                  </td>

                  <!-- Name Column with Fixed Width and Text Wrapping -->
                  <td class="px-1 py-2 max-w-48 break-words text-gray-700">
                    {{ layer.name }}
                  </td>

                  <td class="px-1 py-2 break-words text-gray-700">
                    {{ layer.isLandUse ? "Y" : "N"}}
                  </td>


                  <!-- Area Column -->
                  <td class="px-1 py-2 whitespace-nowrap text-gray-700">
                    {{ layer.area | number:'1.0-0' }} (ha)
                  </td>
                  <td class="px-1 py-2 whitespace-nowrap text-gray-700">
                    {{ layer.percentage | number: '1.2-2' }}%
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
            </pdf-draggable>
        }

      }
    }

  </div>
</section>
