

<ng-template actionPortal>
  <div class="grid grid-cols-3 px-2 items-center flex-grow-1">
    <button
    appTooltip="Back"
    position="left"
    class="text-lg text-black p-0 m-0 hover:text-gray-800 focus:outline-none justify-self-start"
    [routerLink]="['../']"
  >
    <i class="fa fa-arrow-left"></i>
  </button>


  <h3 [tuiSkeleton]="!propertiesState.propertiesLoaded()" class="justify-self-center text-base font-semibold text-gray-800 text-wrap text-center  col-start-2" >{{propertiesState.activeProperty()?.propertyName ?? 'Property'}}</h3>

  <div class="justify-self-end col-start-3 space-x-2">
    <button
    appTooltip="Zoom To Property Boundary"
    position="left"
    class="text-lg text-gray-400 p-0 m-0 hover:text-gray-600 focus:outline-none"
    (click)="zoomTo()"
    >
    <i class="fa fa-magnifying-glass"></i>
    </button>
    <a  position="left"   appTooltip="Print PDF" class="text-lg text-gray-400 p-0 m-0 hover:text-gray-600 focus:outline-none " routerLink="/create-pdf/print">
      <i class="fa fa-print"></i>
    </a>
    </div>


  </div>
</ng-template>
<div class=" w-full flex items-center space-x-2">
<scenario-picker [disabled]="state.loading()" [scenario]="scenariosState.selectedScenario()" (scenarioChanged)="scenariosState.select($event)"  [scenarios]="scenariosState.scenarios()"></scenario-picker>
<button
  (click)="createScenario()"
  class="grid place-items-center text-lg text-white bg-blue-500 hover:bg-blue-600  disabled:bg-gray-400 disabled:cursor-not-allowed rounded-full w-10 h-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-200 ease-in-out shadow-md hover:shadow-lg"
>
  <i class="fa fa-plus"></i>
</button>

<button
  [disabled]="scenariosState.selectedScenario() == null"
  (click)="updateScenario(scenariosState.selectedScenario())"
  class="grid place-items-center text-lg text-white bg-yellow-500 hover:bg-yellow-600 disabled:bg-gray-400 disabled:cursor-not-allowed rounded-full w-10 h-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 transition duration-200 ease-in-out shadow-md hover:shadow-lg"
>
  <i class="fa fa-pencil"></i>
</button>
</div>

  <tui-accordion style="padding-top:1rem">
    <tui-accordion-item>
      <span class="flex justify-center align-center">Property Layers Area Breakdown</span>
      <ng-template tuiAccordionItemContent>
        <div class="container">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Area (ha)</th>
              </tr>
            </thead>
            <tbody>
              @for (layer of state.featureLayers(); track layer.layerID) {
                @if(layer.isLandUse)
                {
                  <tr>
                    <td>{{ layer.name }}</td>
                    <td>{{ layer.area | number:'1.0-0' }}</td>
                  </tr>
                }
              }

            </tbody>
          </table>
          <div class="grid grid-cols-[auto,1fr] gap-2.5 items-center">
            <p>Cumulative Area:</p> <span>{{ state.overview().cumulativeArea | number:'1.0-0' }} (ha)</span>
            <p>Property Area:</p> <span>{{ state.overview().propertyArea | number:'1.0-0' }} (ha)</span>
            <p>% of Property:</p> <span>{{ state.overview().percentageOfProperty | number: '1.2-2' }}</span>
            <p>Balance:</p> <span>{{ state.overview().balance | number:'1.0-0' }} (ha)</span>
          </div>
        </div>
        </ng-template>
    </tui-accordion-item>
  </tui-accordion>


<div [tuiSkeleton]="state.loading()" class="layer-panel-container">
  <div class="layer-panel-header">
    @if(scenariosState.selectedScenario() == null)
    {
      <button appTooltip="Create Layer" tooltipPosition="left" type="button" (click)="createLayer()" class="button button--blue button--action justify-self-end">
        <i class="fa fa-plus"></i>
      </button>
    }

  </div>

    <tui-scrollbar>
      <div class="layer-panel-content" overflowPadding>
        @if (scenariosState.selectedScenario() != null) {
          <div class="flex gap-2 items-center bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative m-2" role="alert">
            <span><i class="fa fa-info-circle text-3xl"></i></span>
              <p class="text-sm">
                Property Layer adding and ordering is only possible when not viewing a scenario
              </p>
            </div>
        }
      <tui-accordion class="example-list"  cdkDropList (cdkDropListDropped)="onDrop($event)" >
        @for (layer of state.featureLayers(); track layer.layerID) {
            <tui-accordion-item cdkDrag [cdkDragData]="layer"  [open]="state.selectedLayer()?.layerID == layer.layerID" class="no-content-padding example-box" [noPadding]="true">
              <app-layer-control [selectable]="true" [formControl]="selectedLayerControl" [value]="layer" [layer]="layer.mapLayer" [map]="mapService.map" >
                @if(state.canSort())
                {
                  <ng-template  layerControlDrag>
                    <div cdkDragHandle class="cursor-grab">
                      <span><i class="fa fa-grip-vertical text-4xl text-gray-300"></i></span>
                      </div>
                    </ng-template>
                }
                <ng-template layerControlPreview ><legend-icon stopPropagation (click)="editLayerDetails(layer)" [rule]="layer.style.rules[0]"></legend-icon>
               </ng-template>
                <ng-template layerControlActions>

                <button  (click)="state.toggleDigitize(layer)"
                class="flex items-center justify-center w-8 h-8 rounded-full text-white"
                   [ngClass]="state.layerDigitizeTargetID() === layer.layerID ? 'bg-yellow-500 hover:bg-yellow-600' : 'bg-blue-500 hover:bg-blue-600'"
                appTooltip="Draw Features"
                toolTipPosition="left"
                stopPropagation
              >
              <i class="fa transition-transform duration-300 origin-center" [ngClass]="state.layerDigitizeTargetID() === layer.layerID ? 'fa-plus rotate-45' : 'fa-plus rotate-0'"></i>
                </button>
                @if (state.layerEditTargetID() == layer.layerID) {
                <button
                  (click)="state.bulkSaveFeatureChanges(layer.layerID)"
                  class="flex items-center justify-center w-8 h-8 rounded-full text-white bg-green-500 hover:bg-green-600"
                  appTooltip="Save Layer Geometry Changes"
                  toolTipPosition="left"
                  stopPropagation
                >
                  <i class="fa fa-check"></i>
                </button>
                <button
                  (click)="state.cancelLayerEdit(layer)"
                  class="flex items-center justify-center w-8 h-8 rounded-full text-white bg-yellow-500 hover:bg-yellow-600"
                  appTooltip="Cancel Edit"
                  toolTipPosition="left"
                  stopPropagation
                >
                  <i class="fa fa-xmark"></i>
                </button>
                } @else {
                <button
                  (click)="state.editLayer(layer)"
                  class="flex items-center justify-center w-8 h-8 rounded-full text-white bg-blue-500 hover:bg-blue-600"
                  appTooltip="Edit Layer Geometry"
                  toolTipPosition="left"
                  stopPropagation
                >
                  <i class="fa fa-pencil"></i>
                </button>
                }
                <button
                  class="flex items-center justify-center w-8 h-8 rounded-full text-white bg-blue-500 hover:bg-blue-600"
                  (click)="downloadFile(layer.layerID)"
                  appTooltip="Download Layer"
                  toolTipPosition="left"
                  stopPropagation
                >
                  <i class="fa fa-download"></i>
                </button>

                <button
                (click)="state.deleteLayer(layer.layerID)"
                class="flex items-center justify-center w-8 h-8 rounded-full text-white bg-red-500 hover:bg-red-600"
                appTooltip="Delete Layer"
                toolTipPosition="left"
                stopPropagation
              >
                <i class="fa fa-trash"></i>
              </button></ng-template>
              </app-layer-control>
              <ng-template tuiAccordionItemContent>
                <tui-scrollbar>
                  <div style="max-height: 300px; padding-inline:var(--size-3)">
                    @for (feature of layer.features; track feature.featureID) {
                       <div #featureItem  [id]="'feature-item-'+feature.featureID" (click)="onClick(feature.olFeature)" (mouseover)="onHover(feature.olFeature)" (mouseout)="onMouseOut(feature.olFeature)" class="feature-item" [ngClass]="{'feature-item--selected': selectedFeature() == feature.featureID || hoveredFeature() == feature.featureID}"  >
                        <div  class="feature-item-description">
                          <span>{{ feature.name }}</span>
                        </div>
                          <div class="feature-actions">
                            @if (state.featureEditTargetID() == feature.featureID) {
                              <button
                                (click)="state.saveFeatureChanges([layer.layerID, feature.featureID])"
                                stopPropagation
                                class="flex items-center justify-center w-8 h-8 rounded-full text-white bg-green-500 hover:bg-green-600"
                                appTooltip="Save Geometry Changes"
                                toolTipPosition="left"
                              >
                                <i class="fa fa-check"></i>
                              </button>
                              <button
                                (click)="state.cancelFeatureEdit(feature);"
                                stopPropagation
                                class="flex items-center justify-center w-8 h-8 rounded-full text-white bg-yellow-500 hover:bg-yellow-600"
                                appTooltip="Cancel Edit"
                                toolTipPosition="left"
                              >
                                <i class="fa fa-xmark"></i>
                              </button>

                              } @else {
                              <button
                                (click)="state.editFeature(feature)"
                                stopPropagation
                                class="flex items-center justify-center w-8 h-8 rounded-full bg-blue-500 hover:bg-blue-600 text-white"
                                appTooltip="Edit Geometry"
                                toolTipPosition="left"
                              >
                                <i class="fa fa-pencil"></i>
                              </button>
                              }

                              <button
                              stopPropagation
                              (click)="onDeleteFeature(layer,feature, feature.name)"
                              class="flex items-center justify-center w-8 h-8 rounded-full bg-red-500 hover:bg-red-600 text-white"
                              appTooltip="Delete Geometry"
                              toolTipPosition="left"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </div>
                      </div>



                    }
                  </div>
                </tui-scrollbar>
            </ng-template>
            </tui-accordion-item>
          }

      </tui-accordion>
    </div>
    </tui-scrollbar>


</div>


<ng-template #featureDrawToolbarTemplate>
  <app-toolbar>
    @for (definition of drawTypeDefinitions; track definition) {
      @if (state.selectedLayer()?.kind == definition.layerKind) {
          <map-digitize [differenceTargetLayers]="state.vectorLayers()" EskTool #digitize [active]="definition.active" [definition]="definition" [intersectTargetFeatures]="[propertiesState.activeProperty().boundaryFeature]" (drawend)="createFeature($event,state.selectedLayer())"  [map]="mapService.map" >
            <map-snap [disabled]="!digitize.active()" [baseInteraction]="digitize.drawInteraction()" [map]="mapService.map" [snapFeatures]="[propertiesState.activeProperty().boundaryFeature]" [snapLayers]="state.vectorLayers()" [active]="digitize.active()"></map-snap>
          </map-digitize>
      }
    }
  </app-toolbar>
</ng-template>

<ng-template #layerEditToolbarTemplate>
  <app-toolbar>
    <map-select (delete)="onSelectDelete(featureLayerService.currentlyEditingLayer(),$event)" EskTool [active]="false" [map]="mapService.map" [layers]="[spatial.modifyLayer]"></map-select>
    <translate EskTool [map]="mapService.map" [layers]="[spatial.modifyLayer]" [active]="false"></translate>
    <map-modify EskTool #modify [shouldRemove]="state.isFeatureEditing()" [active]="true"  [map]="mapService.map" [source]="spatial.modifySource">
      <map-snap [disabled]="!modify.active()" [baseInteraction]="modify.modifyInteraction()" [map]="mapService.map" [snapFeatures]="[propertiesState.activeProperty().boundaryFeature]" [snapLayers]="state.vectorLayers()" [active]="modify.active()"></map-snap>
    </map-modify>
    <map-doughnut-modify  EskTool [map]="mapService.map" [layers]="[spatial.modifyLayer]" (modified)="featuresChanged($event)"></map-doughnut-modify>
  </app-toolbar>
</ng-template>

<ng-template #featureEditToolbarTemplate>
  <app-toolbar>
    <translate EskTool [map]="mapService.map" [active]="false" [layers]="[spatial.modifyLayer]"></translate>
    <map-modify [differenceTargetLayers]="state.vectorLayers()" [active]="true"  [shouldRemove]="state.isLayerEditing()" [intersectTargetFeatures]="[propertyBoundaryFeature()]" EskTool #modify [map]="mapService.map" [source]="spatial.modifySource">
      <map-snap [disabled]="!modify.active()" [baseInteraction]="modify.modifyInteraction()" [map]="mapService.map" [snapFeatures]="[propertiesState.activeProperty().boundaryFeature]" [snapLayers]="state.vectorLayers()" [active]="modify.active()"></map-snap>
    </map-modify>
  </app-toolbar>
</ng-template>
