import { effect, inject, signal } from '@angular/core';
import { createInjectable } from 'ngxtension/create-injectable';
import {
  combineLatest,
  concatMap,
  delay,
  EMPTY,
  endWith,
  filter,
  finalize,
  from,
  fromEvent,
  map,
  Observable,
  of,
  startWith,
  switchMap,
  take,
  tap,toArray
} from 'rxjs';
import { PropertiesState } from 'src/app/map/features/property/data-access/state';
import { signalSlice2 } from 'src/app/map/features/property/signal-slice';
import { PdfService } from '../data-access/pdf.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MapService } from 'src/app/map/data-access/map.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { mapArray } from 'ngxtension/map-array';
import { ScenarioState } from 'src/app/map/features/property/features/feature-layers/data-access/scenario/state';
import { FeatureLayerState } from 'src/app/map/features/property/features/feature-layers/data-access/state';
import { SignalRService } from '../../services/signalR.service';
import { filterNil } from 'ngxtension/filter-nil';
import { PropertiesService } from 'src/app/map/features/property/data-access/properties.service';
import { FeatureLayersService, LayerDTO } from 'src/app/map/features/property/features/feature-layers/data-access/feature-layers.service';
import { processFeatureLayer, processLayerFeatures } from 'src/app/map/features/property/features/feature-layers/data-access/utils';
import OlParser from 'geostyler-openlayers-parser';
import { stableSort } from 'ol/array';
import { Spatial } from './spatial';
import { state } from '@angular/animations';
import { basemapDefinitions } from 'src/app/map/util/basemaps';
import { transformExtent } from 'ol/proj';
import { parseAndSignalElement } from '../data-access/util';
import { processPropertyOperator } from 'src/app/map/features/property/data-access/utils';
import { Vector } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import { area } from '@turf/turf';
import { Property } from 'src/app/map/features/property/data-access/models/property.model';
import { ScenarioService } from 'src/app/map/features/property/features/feature-layers/data-access/scenario/scenario.service';
import { ElementLayoutDetails, PaperDefinition } from '../data-access/models/models';
import { convertBoundingBoxToCorners } from './util';

type InitialState = {
  jobID: string | null;
  accessToken: string | null;
  printDetails: any | null;
  propertyID: string | null;
  property: Property | null;
  scenarioID: string | null;
  extent: [number, number, number, number];
  extentCorners: number[];
  featureLayers: LayerDTO[];
  mapScale: number | null;
  selectedBaseMap: string | null;
  baseMapLayer: any | null;
  baseMapLoaded: boolean;
  featureLayersLoaded: boolean;
  elements: ElementLayoutDetails[];
  paperDefinition: PaperDefinition;
  processing: boolean;
  mapInPaperCoordinates: any;
};


const INITIAL_STATE:InitialState  =
{
  jobID: null,
  accessToken: null,
  printDetails: null,
  propertyID: null,
  property: null,
  scenarioID: null,
  extent: [0,0,0,0],
  extentCorners: null,
  featureLayers: [],
  mapScale: null,
  selectedBaseMap: null,
  baseMapLayer: null,
  baseMapLoaded: false,
  featureLayersLoaded: false,
  elements: [],
  paperDefinition: {
    size: 'A4',
    orientation: 'Portrait',
  },
  processing: false,
  mapInPaperCoordinates: null
}


export const GeneratePDFState = createInjectable(
  () => {
    const featureLayersAPI = inject(FeatureLayersService);
    const signalR = inject(SignalRService);
    const propertiesAPI = inject(PropertiesService);
    const scenarioAPI = inject(ScenarioService);
    const spatial = inject(Spatial)
    const pdfAPI = inject(PdfService);
    const route: ActivatedRoute = inject(ActivatedRoute);
    const jobID$ = route.queryParams.pipe(map(params => params["job"]))
    const token$ = route.queryParams.pipe(map(params => params["token"]));
    const parser = new OlParser();

    const STATE = signalSlice2({
      initialState: INITIAL_STATE,
      sources: [
        jobID$.pipe(map(jobID => ({jobID}))),
        token$.pipe(map(accessToken => ({accessToken}))),
        (state) => combineLatest([state.accessToken$.pipe(filterNil()),state.jobID$.pipe(filterNil())]).pipe(
          switchMap(([ accessToken,jobID]) => signalR.startConnection(accessToken).pipe(switchMap(() => signalR.joinRoom(jobID)),switchMap(() => signalR.getPrintDetails(jobID)))),
          map(model => ({
            ...model,
            elements: model.elements.map((element: any) =>
              parseAndSignalElement(element)
            ),
          })),
          map(({paperDefinition, scenarioID, elements,extent,propertyID,selectedBaseMap, mapScale}) => ({paperDefinition,scenarioID, elements,extent,propertyID,selectedBaseMap, mapScale}))),

         (state) => combineLatest([state.accessToken$.pipe(filterNil()), state.propertyID$.pipe(filterNil())]).pipe(
          switchMap(([accessToken, propertyID]) => {
          propertiesAPI.setAccessToken(accessToken)
          return propertiesAPI.get(propertyID)
         }),processPropertyOperator(),map(property => ({property}))),

         (state) => combineLatest([state.accessToken$.pipe(filterNil()), state.propertyID$.pipe(filterNil()),state.scenarioID$]).pipe(
          switchMap(([accessToken, propertyID,scenarioID]) =>
          {

            if(scenarioID == null)
            {
              return of([accessToken, propertyID, null])
            }

            scenarioAPI.setResourceParams({propertyID: propertyID})
            scenarioAPI.setAccessToken(accessToken);

            return scenarioAPI.get(scenarioID).pipe(map(scenario => ([accessToken, propertyID,scenario])))
          }),
          switchMap(([accessToken, propertyID,scenario]: [any,any,any]) => {
            featureLayersAPI.setAccessToken(accessToken)
            featureLayersAPI.setResourceParams({propertyID: propertyID})
          return featureLayersAPI.GetAll('', { id: scenario?.featureLayers });
         }),
         switchMap(featureLayers =>
          from(featureLayers).pipe(
              concatMap(featureLayer =>
                  of(featureLayer).pipe(
                      processFeatureLayer({ parser }),
                      processLayerFeatures({}),
                  )
              ),
              toArray()
          )
      ),map((featureLayers => ({featureLayers: featureLayers, featureLayersLoaded: true})))
        ),
        (state) => state.selectedBaseMap$.pipe(filterNil(),map(selectedBaseMap => {
          const selectedBaseMapDefinition = basemapDefinitions.find(
            (definition) => definition.display_name == selectedBaseMap
          );
          return selectedBaseMapDefinition;
        }),switchMap((selectedBaseMapDefinition => spatial.loadBaseMap(selectedBaseMapDefinition).pipe(map(layers => layers[0])))),map(baseMapLayer => ({baseMapLayer}))),
        (state) => state.baseMapLayer$.pipe(filterNil(),map(layer => layer.getSource()),switchMap(source => fromEvent(source, 'tileloadend').pipe(delay(200))),map(() => ({baseMapLoaded:true}))),
        (state) => state.extent$.pipe(filterNil(),map(extent => convertBoundingBoxToCorners(extent)),map(extentCorners => ({extentCorners})))
      ],
      selectors: (state) => ({
        spatialFeatureLayers: () => state.featureLayers().map(layer => layer.mapLayer),
        featureLayerLegendMetaData: () => state.featureLayers().map(layer => {return {name: layer.name, style: layer.style, area: layer.area, percentage: (layer.area / state.property().areaHaCalculated) * 100,
          isLandUse: layer.isLandUse}}).sort((a, b) => Number(b.isLandUse) - Number(a.isLandUse)),
      }),
      actionSources: {
        setCorners: (state, action$: Observable<number[]>) => action$.pipe(map(val => ({extentCorners: val}))),
        setMapInPaperCoordinates: (state, action$: Observable<number[]>) => action$.pipe(map(val => ({mapInPaperCoordinates: val}))),
      },
    });


    combineLatest([STATE.baseMapLoaded$.pipe(filter(val => val === true)),STATE.featureLayersLoaded$.pipe(filter(val => val === true)), STATE.jobID$.pipe(filterNil()), STATE.extentCorners$.pipe(filterNil()), STATE.mapInPaperCoordinates$.pipe(filterNil())]).pipe(
    takeUntilDestroyed(),
    delay(600)
  ).subscribe(([baseMapLoaded,featureLayersLoaded,jobID,extentCorners,mapInPaperCoordinates]) => {
            signalR.hubConnection.invoke(
          'RenderCompleted',
          jobID,
          extentCorners,
          mapInPaperCoordinates)
  });


    effect(() => {
      const baseMapLayer = STATE.baseMapLayer();

      if(baseMapLayer == null)
        return;

      baseMapLayer.setVisible(true);


      spatial.map.getLayers().insertAt(0,baseMapLayer);
    });



    effect(() => {

      const featureLayers = STATE.spatialFeatureLayers();

      if(featureLayers == null || featureLayers.length == 0)
        return;


      spatial.map.getLayers().extend(featureLayers)

    })


    effect(() => {

      const property = STATE.property() as Property;


      if(property == null)
        return;

      const areaSource = spatial.areaSource as VectorSource;
      const boundariesSource = spatial.boundariesSource as VectorSource;

      areaSource.addFeature(property.areaFeature);
      boundariesSource.addFeature(property.boundaryFeature);



    });


    effect(() => {
      const extent = STATE.extent();

      if(extent == null)
        return;


      spatial.map.getView().fit(transformExtent(extent, 'EPSG:4326', 'EPSG:3857'));
    })

    return STATE;
  },
  { providedIn: 'scoped' }
);
